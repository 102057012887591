<template>
  <div class="home">
    <div class="swiper-container bannerHome">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <a :href="`${apiName}special?t=4`" title="党纪学习教育">
            <img class="img_c1" src="../assets/img/temp/banner_djxxjy.jpg" alt="">
          </a>
        </div>
        <div class="swiper-slide">
          <a :href="`${apiName}special?t=2`" title="学习贯彻习近平新时代中国特色社会主义思想主题教育">
            <img class="img_c1" src="../assets/img/banner_ztjy3.png" alt="">
          </a>
        </div>
        <div class="swiper-slide">
          <a :href="`${apiName}special?t=1`" title="常态化纵深推进“三抓三促”行动">
            <img class="img_c1" src="../assets/img/banner_img6.png" alt="">
          </a>
        </div>
        <div class="swiper-slide">
          <a :href="`${apiName}special`" title="深入学习贯彻党的二十大精神">
            <img class="img_c1" src="../assets/img/banner_xxesd01.png" alt="">
          </a>
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_img8.jpg" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner3.jpg" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner4.jpg" alt="">
        </div>
        <div class="swiper-slide">
          <a :href="`${apiName}special?t=3`" title="国企深化改革">
            <img class="img_c1" src="../assets/img/temp/banner_gqshgg.jpg" alt="">
          </a>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-banner"></div>
    </div>
    <div class="mian mainHome">
      <div class="wrap">
        <div class="homeSecHd">
          <span class="homeSecHdDian"></span>
          <span class="homeSecHTitle">新闻中心</span>
          <span class="homeSecHdDian"></span>
        </div>
        <!-- 要闻 -->
        <div class="home_pre clearfloat">
          <div class="home_pre_l fl">
            <img src="../assets/img/ico_tt.png" alt="">
          </div>
          <!-- <div class="home_pre_r fr">
            <img src="../assets/img/home_top_img01.png" alt="">
          </div> -->
          <div class="inner">
            <el-carousel height="58px" direction="vertical" :interval="2500" indicator-position="none">
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <div class="single-line">
                  <span class="newsTopTime">
                    <span class="newsTopTimeDay">{{dayjs(item.update_time).format('DD')}}</span>
                    <span class="newsTopTimeMouth">{{dayjs(item.update_time).format('MM')}}月</span>
                  </span>
                  <a v-if="!item.is_link || item.is_link === 0" class="intro-btn" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">{{ item.title }}</a>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- 新闻中心 -->
        <div class="homeNews">
          <el-row :gutter="60">
            <el-col :span="11">
              <div class="swiper-container bannerHomeSec01">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in newsLeft" :key="index">
                    <a target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}detail?id=${item.id}` : item.link_url">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination-sec01"></div>
              </div>
            </el-col>
            <el-col :span="13">
              <div class="homeNewsBox homeNewsBox01">
                <div class="homeSecNewHd clearfloat">
                  <div class="fl"
                    :class="{'on' : hdActive === index}" 
                    v-for="(item, index) in hdList" 
                    :key="index"
                    @mouseenter="hdShow(index)">
                    <h3>
                      <a :href="`${apiName}${item.url}`">{{item.name}}</a>
                    </h3>
                    <span></span>
                  </div>
                </div>
                <ul class="news_list news_list02" v-if="hdActive === 0">
                  <li v-for="(item,index) in newsRight01" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="hdActive === 1">
                  <li v-for="(item,index) in newsRight02" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="hdActive === 2">
                  <li v-for="(item,index) in newsRight03" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="homeNewsec02">
          <el-row :gutter="40">
            <el-col :span="9">
              <div class="homeNewsec02Hdf">
                <a :href="`${apiName}news?t=3`">员工风采</a>
              </div>
              <div class="homeNewsec02Wrap">
                <ul class="news_list news_list02">
                  <li v-for="(item,index) in newsListPersonal" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="homeNewsec02Hdf">
                <a :href="`${apiName}news?t=4`">媒体聚焦</a>
              </div>
              <div class="homeNewsec02Wrap">
                <ul class="news_list news_list02">
                  <li v-for="(item,index) in newsListMedial" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="homeNewsec02Hdf">
                <a :href="`${apiName}news?t=5`">视频集锦</a>
              </div>
              <div class="homeNewsec02Wrap homeNewsec02Video">
                <!-- <a :href="`${apiName}news?t=5`" title="视频集锦">
                  <img src="../assets/img/home_vedio_img02.jpg" alt="">
                </a> -->
                <div class="swiper-container bannerHomeSecVideo">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in videoList" :key="index">
                      <a target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}detail?id=${item.id}` : item.link_url">
                        <img :src="item.img" alt="">
                        <!-- <p class="single-line">{{item.title}}</p> -->
                      </a>
                    </div>
                  </div>
                  <div class="swiper-button-prev swiper-button-prev-video"></div>
                  <div class="swiper-button-next swiper-button-next-video"></div>
                  <!-- <div class="swiper-pagination swiper-pagination-sec01"></div> -->
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="homeNewsec03">
          <div class="swiper-container bannerHomeNewsec03">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in HomeNewsec03List" :key="index">
                <a
                  v-if="!item.is_link || item.is_link === 0"
                  :href="`${apiName}special?t=${index}`" :title="item.title">
                  <img :src="item.img" alt="">
                </a>
                <a
                  v-if="item.is_link === 1"
                  target="_blank"
                  :href="item.link_url.indexOf('https://') === 0 || item.link_url.indexOf('http://') === 0 ? item.link_url : `${apiName}${item.link_url}`"
                  :title="item.title">
                  <img :src="item.img" alt="">
                </a>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination-nessec03"></div>
          </div>
        </div>

      </div>

      <!-- 业务领域 -->
      <div class="homeBusiness">
        <div class="homeBusinessBg homeBusinessBg01"></div>
        <div class="homeBusinessBg homeBusinessBg02"></div>
        <div class="wrap">
          <div class="homeSecHd homeSecHdLeft">
            <span class="homeSecHdDian"></span>
            <span class="homeSecHTitle">公司业务</span>
            <span class="homeSecHdDian"></span>
          </div>
          <div class="homeBusinessWrap">
            <div class="swiper-container bannerSpecail" id="bannerSpecail">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                  v-for="(item, index) in businessList" 
                  :key="index"
                  >
                  <a :href="`${apiName}business?t=${index}`" target="_blank" @mouseenter="businessShow(index, item)"
                  @mouseleave="businessClose">
                    <transition name="el-zoom-in-center">
                      <div class="homeBusinessUp" v-show="businessActive === index && businessActive01">
                        <div class="homeBusinessUpTitle">
                          {{item.name}}
                        </div>
                        <div class="homeBusinessUpWrap">
                          {{item.des}}
                        </div>
                        <div class="homeBusinessBottom">
                          <span>查看更多</span> <i>→</i>
                        </div>
                      </div>
                    </transition>
                    <transition name="el-zoom-in-center">
                      <div class="homeBusinessDown" v-show="businessActive !== index || !businessActive01">
                        <img :src="item.img" alt="">
                        <div class="homeBusinessDownTit">
                          <!-- 担保集团 -->
                          {{item.name}}
                        </div>
                        <div class="homeBusinessBottom">
                          <span>查看更多</span> <i>→</i>
                        </div>
                      </div>
                    </transition>
                  </a>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev swiper-button-prev-specail"></div>
            <div class="swiper-button-next swiper-button-next-specail"></div>
          </div>
        </div>
      </div>
      
      <!-- 纪检举报 -->
      <div class="homeJjjb">
        <div class="homeJjjbBg"></div>
        <div class="wrap homeJjjbBox clearfloat">
          <div class="fl homeJjjbWrap">
            <a :href="`${apiName}report`" target="_blank">
              <div class="homeJjjbHd">纪委举报受理入口 <span class="trans"> →</span></div>
              <div class="homeJjjbLine"></div>
              <div class="homeJjjbGrop">
                <p>
                  举报电话：0931—8835243
                </p>
                <p>
                  举报邮箱：jrzbjjjcs@163.com
                </p>
                <p>
                  举报地址：甘肃省兰州市城关区农民巷8号之1号盛达金融大厦
                </p>
              </div>
            </a>
          </div>
          <div class="fr homeJjjbWrap">
            <a :href="`${apiName}petition`" target="_blank">
              <div class="homeJjjbHd">网上信访信息中心 <span class="trans"> →</span></div>
              <div class="homeJjjbLine"></div>
              <div class="homeJjjbGrop">
                <p>
                  举报电话：0931—8637957
                </p>
                <p>
                  举报邮箱：jrzbxf@ghatg.com
                </p>
                <p>
                  举报地址：甘肃省兰州市城关区农民巷8号之1号盛达金融大厦
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  name: 'home',
  data(){
    return {
      videoList: [
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/home_vedio_img01.jpg'),
        //   id: '1',
        //   cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
        //   creat_time: '2021-05-14'
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/home_vedio_img01.jpg'),
        //   id: '1',
        //   cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
        //   creat_time: '2021-05-14'
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/home_vedio_img01.jpg'),
        //   id: '1',
        //   cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
        //   creat_time: '2021-05-14'
        // },
      ],
      hdActive: 0,
      hdList: [
        {
          name: '公司要闻',
          id: 1,
          url: 'news'
        },
        {
          name: '子公司动态',
          id: 2,
          url: 'news?t=1'
        },
        {
          name: '党建要闻',
          id: 3,
          url: 'party'
        },
      ],
      newsTop: [],
      newsLeft: [
        // {
        //   title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
        //   img: require('../assets/img/home_vedio_img01.jpg'),
        //   id: '1',
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研',
        //   img: require('../assets/img/home_vedio_img02.jpg'),
        //   id: '2'
        // }
      ],
      newsRight: [
        {
          title: '集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
      ],
      newsRight01: [],
      newsRight02: [],
      newsRight03: [],
      value01: '国家部委网站',
      value02: '省直部门网站',
      value03: '省属国企网站',
      value04: '国内同行网站',
      value05: '金融机构网站',
      HomeNewsec03List: [
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/cd_img01.png'),
        //   id: '1',
        //   creat_time: '2021-05-14'
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/cd_img02.png'),
        //   id: '1',
        //   creat_time: '2021-05-14'
        // },
      ],
      businessList: [
        {
          id: '1',
          name: '融资担保',
          url: '',
          img: require('../assets/img/temp/busicess_rzdb.png'),
          des: '甘肃省融资担保集团股份有限公司成立于2012年2月，注册资本50亿元，是经甘肃省工业和信息化厅批准，由甘肃省公路航空旅游投资集团有限公司、甘肃省金融资本集团有限公司出资设立的国有大型专业担保机构。'
        },
        {
          id: '2',
          name: '小额贷款',
          url: '',
          img: require('../assets/img/temp/busicess_xedk.jpg'),
          des: '甘肃公航旅小额贷款股份有限公司成立于2012年7月，注册资本20亿元,是经甘肃省政府金融工作办公室批准成立的公航旅集团旗下甘肃省金融资本集团有限公司的控股子公司，现为省内资本规模最大的小额贷款公司。公司主营抵押贷款、保证贷款、信用贷款、供应链金融类贷款及个性化融资类产品服务。'
        },
        // {
        //   id: '3',
        //   name: '产权交易',
        //   url: '',
        //   img: require('../assets/img/temp/busicess_cqjy.jpg'),
        //   des: '甘肃省产权交易所集团前身为1998组建的甘肃省产权交易所，是经甘肃省人民政府和国务院清理整顿各类场所部际联席会批准保留的全省唯一一家从事国有资产流转和股权融资服务的综合类产权交易机构，现已发展为全省最具影响力的资产流转和股权融资平台。'
        // },
        {
          id: '4',
          name: '供应链管理',
          url: '',
          img: require('../assets/img/temp/busicess_gyl.jpg'),
          des: '甘肃公航旅供应链管理有限公司于2016年11月4日成立，注册资本10.54亿元人民币，由甘肃省金融资本集团有限公司发起设立，2020年12月引入甘肃博大环亚国际贸易有限公司、甘肃华阳泰供应链管理有限公司战略投资，实现混合所有制改革。公司业务以核心客户为依托，以真实贸易背景为前提，通过介入供应链产业的各个环节，为核心企业及其下游提供配套管理服务。'
        },
        {
          id: '5',
          name: '资产管理',
          url: '',
          img: require('../assets/img/temp/busicess_zcgl.jpg'),
          des: '甘肃公航旅资产管理有限公司成立于2015年11月5日，注册资本10亿元人民币，是甘肃省金融资本集团有限公司着眼有效整合金融板块资源，延伸金融板块产业链条,发挥整体协同效应,独家发起设立的一人有限责任公司。'
        },
        {
          id: '6',
          name: '典当业务',
          img: require('../assets/img/temp/busicess_ddgs.jpg'),
          des: '甘肃公航旅典当有限责任公司于2016年6月23日成立，是经甘肃省商务厅、兰州市公安局联合批准设立的典当公司，注册资本2亿元人民币，是目前甘肃省资金规模最大、资金实力最强的国有典当公司。'
        },
        {
          id: '7',
          name: '私募基金',
          img: require('../assets/img/temp/busicess_jj.jpg'),
          des: '丝绸之路公航旅投资管理有限公司成立于2014年12月，注册资本5000万元。主要从事产业基金投资管理、投资咨询业务,已建立一套科学高效的投资决策、风险控制运营体系。'
        },
        {
          id: '8',
          name: '金融仓储',
          img: require('../assets/img/temp/busicess_cc.jpg'),
          des: '甘肃公航旅金融仓储有限公司成立于2016年10月，甘肃省金融资本集团有限公司控股的国有法人机构，注册资本9.44亿元，是全国资本金最大的金融仓储公司。'
        },
        {
          id: '9',
          name: '融资租赁',
          img: require('../assets/img/temp/busicess_rzzl.jpg'),
          des: '甘肃公航旅融资租赁有限公司是由甘肃省金融资本集团有限公司、甘肃公航旅集团（香港）有限公司和甘肃航空产业投资有限公司发起设立的合资公司，成立于2016年10月，注册资本26.09亿元人民币，系甘肃省公路航空旅游投资集团有限公司（中国500强）成员企业，是甘肃省规模最大的省属国有融资租赁公司。'
        },
        // {
        //   id: '10',
        //   name: '要素交易',
        //   img: require('../assets/img/temp/busicess_ysjy.jpg'),
        //   des: '甘肃东方种业交易中心股份有限公司是经甘肃省政府批准设立的面向全国的以种子为主的农产品现货交易场所,成立于2017年1月，主要国有股东为甘肃省金融资本集团有限公司、张掖市现代农业投资股份有限公司。'
        // },
        {
          id: '11',
          name: '商业保理',
          img: require('../assets/img/temp/busicess_sybl.jpg'),
          des: '公航旅商业保理(深圳)有限公司是甘肃省金融资本集团有限公司独家发起设立的一人有限责任公司，于2017年6月在深圳市前海深港合作区注册成立，注册资本5亿元，是首家在甘肃省内开展业务的商业保理机构。'
        },
      ],
      businessActive: -1,
      businessActive01: false,
      newsListPersonal: [],
      newsListMedial: []
    }
  },
  mounted(){
    this.bannerInit()
    // this.bannerHomeSec01()
    // this.bannerHomeNewsec03()
    this.bannerSpecail()

    this.getImprotNews()
    this.getImgNews()
    this.companyNews()
    this.companyNewsSub()
    this.companyNewsParty()
    this.companyNewsPersonnel()
    this.companyNewsMedail()
    this.specailList()
    this.bannerHomeVideo()
  },
  methods: {
    // 新闻头条
    async getImprotNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImportNews', {company_id: _this.companyId});
      _this.newsTop = data.data.list;
    },
    // 焦点图
    async getImgNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImgNews', {company_id: _this.companyId});
      _this.newsLeft = data.data.list.slice(0,6);
      this.$nextTick(() => {
        _this.bannerHomeSec01()
      })
    },
    // 公司新闻
    async companyNews() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "151", page: 1, pageSize: 10});
      _this.newsRight01 = data.data.list.slice(0,8);
    },
    // 子分公司动态
    async companyNewsSub() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "152", page: 1, pageSize: 10});
      _this.newsRight02 = data.data.list.slice(0,8);
    },
    // 党建要闻
    async companyNewsParty() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "154", page: 1, pageSize: 10});
      _this.newsRight03 = data.data.list.slice(0,8);
    },
    // 员工风采
    async companyNewsPersonnel() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "246", page: 1, pageSize: 6});
      _this.newsListPersonal = data.data.list;
    },
    // 媒体聚焦
    async companyNewsMedail() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "247", page: 1, pageSize: 6});
      _this.newsListMedial = data.data.list;
    },
    // 专题广告
    async specailList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: "249", page: 1, pageSize: 6});
      _this.HomeNewsec03List = data.data.list;
      this.$nextTick(() => {
        _this.bannerHomeNewsec03()
      })
    },
    businessShow(index, item) {
      console.log('index :>> ', index);
      console.log('item :>> ', item);
      var _this = this
      _this.businessActive = index
      _this.businessActive01 = true
    },
    businessClose(){
      var _this = this
      _this.businessActive = -1
      _this.businessActive01 = false
    },
    hdShow(index) {
      var _this = this

      _this.hdActive = index
    },
    // banner
    bannerInit(){
      new Swiper(".bannerHome", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-banner",
          clickable :true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 焦点图
    bannerHomeSec01(){
      new Swiper(".bannerHomeSec01", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-sec01",
          clickable :true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 视频集锦
    async bannerHomeVideo(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: "248", page: 1, pageSize: 6});
      _this.videoList = data.data.list;
      this.$nextTick(() => {
        new Swiper(".bannerHomeSecVideo", {
          autoplay: {
            disableOnInteraction: false,
          },
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next-video',
            prevEl: '.swiper-button-prev-video',
          },
          // pagination: {
          //   el: ".swiper-pagination-sec01",
          //   clickable :true,
          // },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
        })
      })
    },
    // 焦点图-cd
    bannerHomeNewsec03(){
      new Swiper(".bannerHomeNewsec03", {
        effect : 'fade',
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-nessec03",
          clickable :true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 专题专栏
    bannerSpecail(){
      var swiper = new Swiper(".bannerSpecail", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: false,
        slidesPerView : 5,
        spaceBetween : 10,
        navigation: {
          nextEl: '.swiper-button-next-specail',
          prevEl: '.swiper-button-prev-specail',
          hideOnClick: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
      var comtainer = document.getElementById('bannerSpecail');
      comtainer.onmouseenter = function () {
        swiper.autoplay.stop();
      };
      comtainer.onmouseleave = function () {
        swiper.autoplay.start();
      }
    }
  }
}
</script>
<style scoped>
.bannerHome {
  height: 612px;
  position: relative;
}
.bannerHome .swiper-wrapper,
.bannerHome .swiper-slide,
.bannerHome img {
  height: 100%;
}
.bannerHome img {
  width: 100%;
}
.swiper-pagination-banner {
  color: #fff;
  font-size: 16px;
}
.swiper-pagination-current {
  font-size: 20px;
}
.secBox01 {
  padding: 60px 0;
  background-image: url(../assets/img/home_bg_sec01.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.secBox01Hd {
  margin-bottom: 30px;
  line-height: 48px;
}
.secBox01Title {
  font-size: 40px;
  color: #8A8A8A;
  text-transform: Uppercase;
}
.secBox01Txt {
  font-size: 30px;
  color: #535353;
}
.secBox01Tab {
  margin-bottom: 54px;
  text-align: center;
}
.secBox01Tab span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  margin: 0 26px;
  font-size: 16px;
  color: #282828;
  background-color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.secBox01Tab span.active {
  color: #fff;
  background-color: #BC8F68;
}
.bannerHomeSec01 {
  height: 352px;
  position: relative;
}
.bannerHomeSec01 .swiper-wrapper,
.bannerHomeSec01 .swiper-slide,
.bannerHomeSec01 img {
  height: 100%;
}
.bannerHomeSec01 img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.bannerHomeSec01 img:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.bannerHomeSec01 .swiper-slide {
  position: relative;
}
.bannerHomeSec01 p {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 118px 0 12px;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
  text-align: left;
}
.bannerHomeSec01 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 0;
  background-color:rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.bannerHomeSec01 .swiper-pagination-bullet-active {
  background-color:#CE251F;
}
.bannerHomeSec01 .swiper-pagination {
  text-align: right;
  left: auto;
  right: 8px;
}
.homeSecHd {
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: #4A4643;
  margin-bottom: 30px;
}
.homeSecHdLeft {
  text-align: left;
}
.homeSecHd span {
  display: inline-block;
  vertical-align: middle;
}
.homeSecHd span.homeSecHdDian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #4A4643;
  margin: 0 10px;
}
.homeSecHd span.homeSecHTitle {
  font-size: 30px;
  font-weight: bold;
}

</style>